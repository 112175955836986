import Vue from 'vue';
import Stopfalshivkam from './components/Stopfalshivkam.vue';
import axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'swiper/dist/css/swiper.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import VModal from 'vue-js-modal';
import VueCookie from 'vue-cookie';

Vue.use(VModal)
Vue.use(PerfectScrollbar)
Vue.use(VueSweetalert2);
Vue.use(VueCookie);
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let box_id = 'page';
document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById(box_id)) {
        new Vue({
            el:         '#' + box_id,
            components: {
                Stopfalshivkam
            },
        });
    }
});
