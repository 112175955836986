<template>
    <div class="wrapper">
        <header class="header">
            <div class="header-top-wrap">
                <div class="header-top">
                    <div class="header-block-edch">
                        <h3 class="header-block-edch__title">Единая Дежурная Часть</h3>
                        <a :href="links.edch" class="header-block-edch__link">Перейти на сайт</a>
                    </div>

                    <div class="header-shop-buttons">
                        <a :href="links.ios" class="header-shop-buttons__btn header-shop-buttons__btn--apple">
                            <div class="header-shop-buttons__icon header-shop-buttons__icon--apple"></div>

                            <div class="header-shop-buttons__wrap">
                                <span class="header-shop-buttons__text">Free download</span>
                                <span class="header-shop-buttons__shop">Apple Store</span>
                            </div>
                        </a>
                        <a :href="links.android" class="header-shop-buttons__btn header-shop-buttons__btn--market">
                            <div class="header-shop-buttons__icon header-shop-buttons__icon--market"></div>

                            <div class="header-shop-buttons__wrap">
                                <span class="header-shop-buttons__text">Free download</span>
                                <span class="header-shop-buttons__shop">Google play</span>
                            </div>
                        </a>
                    </div>
                    <a href="https://edch24.ru/information/video" class="header-video-button">
                        <span class="header-video-button__text">Видео</span>
                    </a>
                    <div v-if="authorized" class="header-shop-buttons__btn header-shop-buttons__btn--market header-userdata-wrap">
                        <div class="header-shop-buttons__wrap">
                            <span class="header-shop-buttons__text">Абонент № {{ user.id_user }}</span>
                            <span class="header-shop-buttons__shop">{{ user.fio }}</span>
                        </div>
                    </div>

                    <div class="header-edch-buttons">
                        <a v-if="!authorized" :href="links.auth" class="header-edch-buttons__btn header-edch-buttons__btn--authorization">
                            Авторизация на ЕДЧ
                        </a>
                        <a v-if="!authorized" :href="links.reg" class="header-edch-buttons__btn header-edch-buttons__btn--registration">
                            Регистрация на ЕДЧ
                        </a>
                        <a v-if="authorized" @click.prevent="logout" class="header-edch-buttons__btn" :class="{loading: logout_process}">
                            {{ logout_process ? '' : 'Выход' }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="container container_flex">
                <div class="header__aside">
                    <div class="header__logo-title"><a class="header__logo" href="/">
                        <svg class="header__logo-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 149.32">
                            <path d="M75,0A74.59,74.59,0,0,0,28.62,133.33v-18.5a60.9,60.9,0,0,1,18.7-94.28c-12.6,8.14-13,28.74-13,28.74v100L53,133.74s.1-29.64.09-56.17c6.41,0,6.11-.09,6.11-.09L74.75,58.79H53.07c0-2.87,0-5.37,0-7.35-.21-19.2,19.09-29,38.7-29,18.94,0,31.56,15.92,32.4,17A60.94,60.94,0,0,1,59,133.86v13.75a76,76,0,0,0,16,1.71A74.66,74.66,0,1,0,75,0Z"></path>
                            <path d="M92,111.61l18.62,0,0,0,0-28.38A24.38,24.38,0,0,0,86.29,58.81H83.11L67.63,77.43a0,0,0,0,0,0,0h0V87.65H86.3V77.48H92v34.13Z"></path>
                            <rect x="67.53" y="93.02" width="18.69" height="18.57"></rect>
                        </svg>
                    </a>
                        <div class="header__title">
                            ФАЛЬШИВКАМ.НЕТ
                        </div>
                        <div class="header__subtitle">
                            Проверьте купюры на подлинность
                        </div>
                    </div>
                    <div class="check-result check-result_mobile"></div>
                    <div class="header-form">
                        <form id="check-banknotes">
                            <div class="header-form__title">
                                Проверка по серийному номеру
                            </div>
                            <div class="header-form__description">
                                Введите серийный номер, который располагается на лицевой стороне купюры (две буквы и семь цифр)
                            </div>
                            <div class="header-form__field header-form__description header-form__item header-form__field_rows">
                                <label class="header-form__label">
                                    Номинал купюры
                                </label>
                                <div class="filter-nominal__list filter-nominal__list_rows">
                                    <div v-for="(item, key) in denominations" @click="checkSelectDenomination(key)"
                                         class="check-filter-nominal__single check-filter-nominal__single_rows"
                                         :class="{active: key === check.denomination_index}">
                                        {{ item.denomination }} ₽
                                    </div>
                                </div>
                            </div>
                            <div class="header-form__item header-form__item_cols">
                                <div class="header-form__field">
                                    <masked-input v-model="check.series" :mask="$_series_mask" class="header-form__input header-form__input_series" placeholder="АА"
                                                  :class="{'error': $_is_series_invalid}"/>
                                    <label class="header-form__label">
                                        Серия
                                    </label>
                                </div>
                                <div class="header-form__field">
                                    <masked-input v-model="check.number" :mask="$_number_mask" placeholder="123456789" placeholder-char="_"
                                                  class="header-form__input header-form__input_number" :class="{'error': $_is_number_invalid}"/>
                                    <label class="header-form__label">
                                        Номер
                                    </label>
                                </div>
                                <div class="header-form__field submit">
                                    <div @click="checkBanknote" class="header-form__button main-button button_gradient">
                                        {{ check.in_process ? 'Проверяем...' : 'Проверить' }}
                                    </div>
                                </div>
                            </div>
                            <div class="header-form__result" :class="$_response_class">
                                <span class="after-response">
                                    Ваш результат:&nbsp
                                </span>
                                <span class="response">
                                    {{ check.response.message }}
                                </span>
                            </div>
                            <div class="header-form__errors">
                                <label class="error">{{ check.response.error }}</label>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="header__aside header__aside_check">
                    <div class="check-result"></div>
                </div>
            </div>
        </header>
        <main class="main" role="main">
            <div class="container">
                <div class="filter">
                    <div class="filter-block">
                        <h3 class="filter-block__title">Выберите номинал купюры и узнайте признаки подлинности</h3>
                        <div class="filter-nominal">
                            <div class="filter-nominal__title">
                                Выберите номинал
                            </div>
                            <div class="filter-nominal__list">
                                <div v-for="(item, key) in denominations" @click="infoSelectDenomination(key)" class="filter-nominal__single"
                                     :class="{active: key === info_current_denomination_index}">
                                    {{ item.denomination }} ₽
                                </div>
                            </div>
                            <div class="filter-nominal__year">
                            <span class="filter-nominal__year-title">
                                Год выпуска
                            </span>
                                <span v-for="(year, key) in $_info_current_years" @click="infoSelectYear(key)" class="filter-nominal__year-single"
                                      :class="{active: key === info_current_year_index}">
                                {{ year }}
                            </span>
                            </div>
                        </div>
                        <div v-if="$_is_contrafait_list_visible" class="filter-fakes">
                            <div class="filter-fakes__title">Реестр изъятых купюр достоинством <span class="filter-fakes__nominal">{{ $_info_current_denomination }} ₽</span></div>
                            <div class="filter-fakes__list-wrap">
                                <perfect-scrollbar :options="{wheelPropagation: false}" class="filter-fakes__list">
                                    <div v-for="group in $_grouped_contrafaits" class="filter-fakes__wrap">
                                        <span v-for="contrafait in group" class="filter-fakes__item">
                                            {{ contrafait.series }} {{ contrafait.number }}
                                        </span>
                                    </div>
                                </perfect-scrollbar>
                            </div>
                        </div>
                    </div>

                    <perfect-scrollbar :options="{wheelPropagation: false}"  class="filter-methods">
                        <a href="#section_description" class="filter-methods__method">
                            Описание
                        </a>
                        <a href="#section_light" class="filter-methods__method">
                            На просвет
                        </a>
                        <a href="#section_loupe" class="filter-methods__method">
                            С лупой
                        </a>
                        <a href="#section_angle" class="filter-methods__method">
                            Под углом
                        </a>
                        <a href="#section_touch" class="filter-methods__method">
                            На ощупь
                        </a>
                        <a href="#section_infraredRadiation" class="filter-methods__method">
                            ИК-излучения
                        </a>
                        <a href="#section_uvRadiation" class="filter-methods__method">
                            УФ-излучения
                        </a>
                    </perfect-scrollbar>
                </div>
                <div v-html="banknote_info" @click="banknoteInfoHandler" class="main-sections"></div>
            </div>
            <div v-html="banknote_popup_info" @click="banknotePopupHandler" v-show="banknote_popup_info" class="banknote-point-popup" :style="$_popup_coords"></div>
        </main>
        <footer class="footer">
            <div class="container">
                <div class="footer__copyright">
                    © СТОПФАЛЬШИВКАМ, 2019
                </div>
            </div>
        </footer>

        <modal class="popup-limit-checks" name="limit" width="330" height="auto">
            <div class="popup-limit-checks__block">
                <h4 class="popup-limit-checks__title">Достигнут лимит проверок!</h4>
                <p class="popup-limit-checks__text">
                    Вы достигли лимита на проверку купюр. Проверяйте без лимита в приложении
                    <a :href="links.edch_page" class="popup-limit-checks__edch">EДЧ</a>
                </p>
                <div class="header-shop-buttons">
                    <a :href="links.ios" class="header-shop-buttons__btn header-shop-buttons__btn--apple">
                        <div class="header-shop-buttons__icon header-shop-buttons__icon--apple"></div>

                        <div class="header-shop-buttons__wrap">
                            <span class="header-shop-buttons__text">Free download</span>
                            <span class="header-shop-buttons__shop">Apple Store</span>
                        </div>
                    </a>
                    <a :href="links.android" class="header-shop-buttons__btn header-shop-buttons__btn--market">
                        <div class="header-shop-buttons__icon header-shop-buttons__icon--market"></div>

                        <div class="header-shop-buttons__wrap">
                            <span class="header-shop-buttons__text">Free download</span>
                            <span class="header-shop-buttons__shop">Google play</span>
                        </div>
                    </a>
                </div>
                <button @click="$modal.hide('limit')" type="button" class="popup-limit-checks__close"></button>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import MaskedInput from 'vue-masked-input';
    import * as actions from '../constants/request_actions';
    export default {
        name:       'Stopfalshivkam',
        components: {MaskedInput},
        props:      {
            denominations: {
                type:    Array,
                default: () => [],
            },
            links: {
                type:    Object,
                default: () => {},
            },
            is_contrafait_list_visible: {
                type:    Boolean,
                default: false,
            },
            contrafait_check_limit: {
                type:    Number,
                default: 0,
            },
            user: {
                type:    Object,
                default: () => {},
            },
        },
        data() {
            return {
                banknote_info:                   '',
                banknote_popup_info:             '',
                banknote_popup_top:              0,
                banknote_popup_left:             0,
                banknote_popup_current_point:    null,
                contrafaits:                     [],
                info_current_denomination_index: 0,
                info_current_year_index:         0,
                check:                           {
                    in_process:         false,
                    denomination_index: 0,
                    series:             '',
                    number:             '',
                    response:           {
                        error:   '',
                        message: '',
                        success: null,
                    },
                },
                logout_process:                  false,
                authorized:                      parseInt(this.$cookie.get('ua')),
                is_contrafait_check_limit:       this.contrafait_check_limit,
            };
        },
        mounted() {
            this.getInfo(actions.INFO);
        },
        methods:    {
            clearResponse() {
                this.check.response.success = null;
                this.check.response.message = '';
            },
            openLimitPopup() {
                this.$modal.show('limit');
            },
            checkBanknote() {
                this.clearResponse();
                if (this.check.in_process) {
                    return;
                }
                if (this.$_is_invalid_data) {
                    this.check.response.error = 'Введите корректные серию и номер';
                    return;
                }
                this.check.in_process = true;
                this.check.response.error = '';
                axios.post(actions.CHECK, {
                    denomination: this.$_check_current_denomination,
                    series: this.check.series,
                    number: this.check.number,
                }).then(response => {
                    this.check.in_process = false;
                    if (!response.data.success) {
                        this.check.response.error = response.data.message;
                        return;
                    }
                    this.check.response.message = response.data.message;
                    this.check.response.success = response.data.is_counterfeit;
                    if (this.is_contrafait_check_limit > 9) {
                        this.openLimitPopup();
                    }
                    this.is_contrafait_check_limit++;
                });
            },
            numberInput() {
                this.check.number = this.check.number.trim();
            },
            checkSelectDenomination(i) {
                this.check.denomination_index = i;
                this.check.series             = '';
                this.check.number             = '';
                this.clearResponse();
            },
            infoSelectDenomination(i) {
                this.info_current_denomination_index = i;
                this.info_current_year_index         = 0;
                this.getInfo(actions.INFO);
            },
            infoSelectYear(i) {
                this.info_current_year_index = i;
                this.getInfo(actions.INFO);
            },
            getInfo(action, sign = null, type = null) {
                axios.post('/banknotes/request/' + action, {
                    denomination: this.$_info_current_denomination,
                    year:         this.$_info_current_year,
                    sign:         sign,
                    type:         type,
                }).then(response => {
                    if (!response.data.success) {
                        return;
                    }
                    if (response.data.banknote_info) {
                        this.banknote_info = response.data.banknote_info;
                        this.contrafaits = response.data.contrafaits;
                    }
                    if (response.data.banknote_popup_info) {
                        this.banknote_popup_info = response.data.banknote_popup_info;
                    }
                });
            },
            banknoteInfoHandler(event) {
                let box;
                if (this.banknote_popup_current_point) {
                    this.banknote_popup_current_point.classList.remove('checked');
                }
                if (
                    !event.target
                    || !event.target.dataset
                    || !event.target.classList.contains('banknote-point__single')
                ) {
                    this.banknote_popup_info = '';
                    return;
                }
                event.target.classList.add('checked');
                box                      = event.target.getBoundingClientRect();
                this.banknote_popup_top  = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) + box.top;
                this.banknote_popup_left = box.left;
                this.getInfo(actions.ADDITIONAL_INFO, event.target.dataset.section, event.target.dataset.type);
                this.banknote_popup_current_point = event.target;

            },
            banknotePopupHandler(event) {
                let elem;
                if (
                    !event.target
                    || !event.target.classList.contains('banknote-point-popup__arrow')
                ) {
                    return;
                }
                elem = event.target.classList.contains('banknote-point-popup__arrow_left')
                    ? this.banknote_popup_current_point.parentElement.previousElementSibling
                    : this.banknote_popup_current_point.parentElement.nextElementSibling;
                if (elem && elem.firstElementChild) {
                    this.banknoteInfoHandler({target: elem.firstElementChild});
                }
            },
            logout() {
                if (this.logout_process) {
                    return;
                }
                this.logout_process = true;
                axios.post(actions.LOGOUT).then(response => {
                    this.logout_process = false;
                    if (!response.data.success) {
                        return;
                    }
                    this.authorized = false;
                    this.user = {};
                });
            },
        },
        computed:   {
            $_grouped_contrafaits() {
                let grouped = [],
                    index   = -1;
                this.contrafaits.forEach((contrafait, i) => {
                    if (i % 3 === 0) {
                        index++;
                    }
                    if (grouped[index] === undefined) {
                        grouped[index] = [];
                    }
                    grouped[index].push(contrafait);
                });
                return grouped;
            },
            $_is_invalid_data() {
                return this.$_is_series_invalid || this.$_is_number_invalid;
            },
            $_check_current_denomination() {
                return this.denominations[this.check.denomination_index].denomination;
            },
            $_check_current_number_length() {
                return this.denominations[this.check.denomination_index].number_length || 7;
            },
            $_info_current_denomination() {
                return this.denominations[this.info_current_denomination_index].denomination;
            },
            $_info_current_year() {
                return this.$_info_current_years[this.info_current_year_index];
            },
            $_info_current_years() {
                let info_denomination = this.denominations[this.info_current_denomination_index];
                if (!info_denomination) {
                    return [];
                }
                return info_denomination.years;
            },
            $_series_mask() {
                return {
                    pattern:          'DD',
                    formatCharacters: {
                        'D': {
                            validate: char => /[а-яА-Я]/.test(char),
                        },
                    },
                };
            },
            $_number_mask() {
                return '1'.repeat(this.$_check_current_number_length);
            },
            $_popup_coords() {
                return {
                    top:  (this.banknote_popup_top - 5) + 'px',
                    left: (this.banknote_popup_left - 5) + 'px',
                };
            },
            $_response_class() {
                return {
                    'header-form__result_yes': this.check.response.success === false,
                    'header-form__result_no': this.check.response.success === true,
                };
            },
            $_is_series_invalid() {
                return !/[А-я]{2}/.test(this.check.series);
            },
            $_is_number_invalid() {
                let number = this.check.number.replace('_', '');
                return !/\d+/.test(number) || number.length !== this.$_check_current_number_length;
            },
            $_is_contrafait_list_visible() {
                return this.authorized && this.is_contrafait_list_visible;
            },
        },
        watch: {
            $_number_mask() {
                this.$nextTick(() => this.check.number = '');
            },
        },
    };
</script>